.App {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #edf2f4;
  font-size: 15px;
  font-family: Arial;
}

option {
  font-weight: bold;
  font-size: 1.3rem;
  margin-left: 10%;
}

.select#select-multiple-native {
  height: 115px;
}

.h3-text {
  margin: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.title-card-year {
  width: fit-content;
  padding: 15px;
  margin-bottom: 30px;
}

@media (max-width: 600px) {
  option {
    font-weight: bold;
    font-size: 1rem;
    margin-left: 5%;
  }
  select#select-multiple-native {
    height: 135px;
  }
  select#Race {
    height: 80px;
  }

  .title-card-year {
    width: fit-content;
  }
}

@media (min-width: 600px) {
  option {
    font-weight: bold;
    font-size: 1.1rem;
    margin-left: 5%;
  }
  select#select-multiple-native {
    height: 163px;
  }

  select#Race {
    height: 90px;
  }

  .title-card-year {
    width: fit-content;
  }
}

@media (min-width: 960px) {
  .option {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 5%;
  }
  select#select-multiple-native {
    height: 170px;
  }

  select#Race {
    height: 110px;
  }

  .title-card-year {
    width: fit-content;
  }
}

@media (min-width: 1280px) {

  option {
    font-weight: bold;
    font-size: 1.3rem;
    margin-left: 5%;
  }
  select#select-multiple-native {
    height: 204px;
  }

  select#Race {
    height: 130px;
  }

  .title-card-year {
    width: fit-content;
  }
}
