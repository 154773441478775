.race-round {
  float: left;
  margin-top: 10px;
  width: 20px;
  height: 50px;
  margin-left: 10px;
}

.flag-round {
  float: left;
  width: 50px;
  margin-top: 10px;
}

.year-race-name {
  float: left;
  text-align: left;
}

.winner-year {
  float: left;
}

.bottom-text {
  margin: 100px;
}

.grid-year {

  padding-inline: 20px;
}

@media (max-width: 600px) {
  .race-round {
    text-align: center;
    margin-top: 15px;
    width: 20px;
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .flag-round {
    width: 50px;
    margin-top: 10px;
  }

  .year-race-name {
    margin-top: 5px;
  }

  .team-year {
    margin-top: 13px;
  }

  .grid-year {
    text-align: center;
    margin-top: 13px;
    padding-inline: 7px;
  }
}

@media (min-width: 600px) {
  .race-round {
    margin-top: 25px;
    width: 20px;
    height: 50px;
    margin-left: 15px;
  }

  .flag-round {
    width: 50px;
    margin-top: 5px;
  }

  .team-year {
    margin-top: 23px;
  }

  .year-race-name {
    margin-top: 12px;
    margin-bottom: 7px;
    width: 280px;
  }

  .grid-year {
    margin-top: 23px;
    text-align: center;
    padding-inline: 20px;
  }
}
@media (min-width: 960px) {
  .race-round {
    text-align: center;
    margin-top: 20px;
    margin-left: 10px;
  }

  .flag-container {
    margin-top: 4px;
    margin-left: 20px;
    width: 40px;
    float: left;
  }

  .flag-round {
    width: 40px;
  }

  .flag-img-year {
    width: 35px;
  }

  .team-year {
    margin-top: 20px;
  }

  .year-race-name {
    margin-top: 10px;
    width: 310px;
  }

  .grid-year {
    margin-top: 8px;
    text-align: center;
    padding-inline: 20px;
  }
}

@media (min-width: 1280px) {
  .race-round {
    text-align: center;
    margin-top: 20px;
    margin-left: 10px;
  }

  .flag-container {
    width: 40px;
    float: left;
    margin-top: 0px;
    margin-left: 0px;
  }

  .flag-round {
    margin-top: 8px;
    margin-left: 18px;
    margin-bottom: 5px;
    width: 40px;
  }

  .flag-img-year {
    height: 23px;
  }

  .team-year {
    margin-top: 25px;
  }

  .year-race-name {
    margin-top: 7px;
    width: 420px;
  }

  .winner-year {
    margin-top: 7px;
  }

  .grid-year {
    margin-top: 12px;
    text-align: center;
    padding-inline: 30px;
  }
}

@media (min-width: 1680px) {
}
