.points-race {
  text-align: center;
}

.team-race {
  margin-left: 5px;
}

.race-pos {
  margin-top: 10px;
  margin-left: 10px;
}

.points-race-number {
  text-align: center;
}

.time-text {
  text-align: center;
  margin-right: 20px;
}

.time-status {
  text-align: center;
  float: right;
  margin-right: 20px;
}

.select {
  width: 200px;
}

.select-label {
  align-self: center;
}

.select-background {
  background-color: white;
}

.select-background-year {
  background-color: white;
}

.select-background-round {
  background-color: white;
}

.option-selector {
  margin-left: 10px;
}

@media (max-width: 600px) {
  .driver-race {
    margin-top: 5px;
    margin-left: 5px;
  }

  .time-text {
    text-align: center;
    margin-right: 20px;
  }

  .points-race {
    text-align: center;
    margin-top: 5px;
  }

  .team-race {
    margin-left: 5px;
  }

  .race-pos {
    margin-top: 10px;
    margin-left: 10px;
  }

  .select {
    width: 200px;
    height: 38px;
  }

  .select-background {
    width:200px;
    height: 20px;
    margin-top: 10px;
  }

  .select-background-year {
    width:  200px;
    height: 20px;
  }

  .select-background-round {
    width: 200px;
    height: 20px;
  }
}

@media (min-width: 600px) {
  .driver-race {
    margin-top: 15px;
    margin-left: 10px;
  }

  .time-text {
    text-align: center;
    margin-right: 20px;
  }

  .points-race {
    text-align: center;
    margin-top: 15px;
  }

  .team-race {
    margin-left: 10px;
  }

  .race-pos {
    width: 20px;
    margin-top: 18px;
    margin-left: 15px;
  }

  .grid-race {
    text-align: center;
    margin-top: 15px;
  }

  .grid-race-number {
    text-align: center;
  }

  .flag-img-race {
    width: 45px;
    height: 30px;
  }

  .flag-race {
    margin-top: 18px;
    width: 50px;
  }

  .select {
    width: 230px;
    height: 38px;
  }

  .select-background {
    width:230px;
    height: 15px;
    margin: 20px;
  }

  
  .select-background-year {
    width:  200px;
    height: 20px;
  }

  .select-background-round {
    width: 200px;
    height: 20px;
  }
}
@media (min-width: 960px) {
  .driver-race {
    margin-top: 15px;
  }

  .time-text {
    text-align: center;
    margin-right: 20px;
  }

  .points-race {
    text-align: center;
    margin-top: 15px;
  }

  .race-pos {
    width: 20px;
    margin-top: 18px;
    margin-left: 15px;
  }

  .grid-race {
    text-align: center;
    margin-top: 15px;
  }

  .grid-race-number {
    text-align: center;
  }

  .flag-img-race {
    width: 45px;
    height: 30px;
  }

  .flag-race {
    margin-top: 18px;
    width: 50px;
  }

  .select {
    width: 230px;
    height: 38px;
  }

  .select-background {
    width:230px;
    height: 15px;
    margin: 30px;
  }

  .select-background-year {
    width:  200px;
    height: 15px;
    margin-left: 150px;
  }

  .select-background-round {
    width: 200px;
    height: 15px;
    margin-right: 150px;
  }
}

@media (min-width: 1280px) {
  .time-text {
    text-align: right;
    margin-right: 20px;
  }

  .flag-img-race {
    width: 60px;
    height: 45px;
  }

  .flag-race {
    margin-top: 15px;
    width: 70px;
  }

  .select {
    width: 250px;
    height: 38px;
  }

  .select-background {
    width: 250px;
    height: 15px;
    margin: 30px;
  }

  .select-background-year {
    width:  200px;
    height: 15px;
    margin-left: 220px;
  }

  .select-background-round {
    width: 200px;
    height: 15px;
    margin-right: 220px;
  }
}

@media (min-width: 1680px) {
  .time-text {
    text-align: center;
    margin-right: 35px;
  }

  .time-status {
    text-align: center;
    float: right;
    margin-right: 20px;
  }

  .flag-img-race {
    width: 60px;
    height: 45px;
  }

  .flag-race {
    margin-top: 15px;
    width: 70px;
  }

  .select {
    width: 250px;
    height: 38px;
  }

  .select-background {
    width: 250px;
    height: 15px;
    margin: 30px;
  }
  .select-background-year {
    width:  200px;
    height: 15px;
    margin-left: 450px;
  }

  .select-background-round {
    width: 200px;
    height: 15px;
    margin-right: 450px;
  }
}
